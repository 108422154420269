// extracted by mini-css-extract-plugin
export var clickArea = "index-module--clickArea--IOVLv";
export var content = "index-module--content--zxy6A";
export var contentHover = "index-module--contentHover--rXxBQ";
export var descBg = "index-module--descBg--5kgZj";
export var descContent = "index-module--descContent--fFcTy";
export var enterpriseService = "index-module--enterpriseService--3ca+3";
export var experienceDemo = "index-module--experienceDemo--Qdu5H";
export var experienceKPI = "index-module--experienceKPI--HWFqP";
export var guidance = "index-module--guidance--2s4iB";
export var guidanceLogo = "index-module--guidanceLogo--MLcxs";
export var hoverShow = "index-module--hoverShow--Qpr6R";
export var hoverUp = "index-module--hover-up--mVAVM";
export var position_left = "index-module--position_left--r96I+";
export var viewData = "index-module--viewData--qmIdC";
export var watchVideo = "index-module--watchVideo--bxmcx";