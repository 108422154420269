import React, {useState, useRef} from 'react';
import Seo from 'components/seo';
import Layout from 'layouts/zh';
import ScrollBarShowLayout from 'components/ScrollBarShowLayout';
import VideoPlayer from 'components/VideoPlayer';
import { postToTask } from 'api/onlineDemo';
import classnames from 'classnames';
import useLoginDialog from 'hooks/useLoginDialog';
import { saTrackOnlineDemoButtonClick, saTrackOnlineDemoLoginClick } from 'utils/statistics';
import { useStaticQuery, graphql } from 'gatsby';
import * as styles from './index.module.less';

const EnterpriseService = () => {
  const [user, setUser] = useState(null)
  const curVideo = useRef<any>(null)
  
  const { detailsYaml } = useStaticQuery(graphql`
    query {
      detailsYaml {
        enterpriseService_overall
        overall_catalogo_mb
        enterpriseService {
          saTitle
          title
          description
          keywords
          experienceDemo
          watchVideo
          experienceKPI
          viewData {
            link
            text
          }
        }
        finger_matting
        right_icon
      }
    }
  `)
  const { enterpriseService_overall, overall_catalogo_mb, finger_matting, enterpriseService, right_icon } = detailsYaml

    // 登录弹窗hook
  const { show: showLoginDialog } = useLoginDialog({
    // 登录成功后回调
    onSuccess: () => {
      saTrackOnlineDemoLoginClick(enterpriseService?.saTitle);
      (window.location.href = '/democenter/enterpriseservice.html')
    },
  })

  const handleLink = (url, button_name) => {
    saTrackOnlineDemoButtonClick(button_name)
    url && window.open(url);
  }


  const handleToTask = (type: string) => {
    if (!user) {
     return showLoginDialog()
    }

    switch (type) {
      case 'experienceDemo':
        postToTask({name: '体验行业Demo'});
        handleLink(enterpriseService?.experienceDemo, '体验行业Demo')
        break;
      case 'experienceKPI':
        postToTask({name: '体验指标拆解工具'});
        handleLink(enterpriseService?.experienceKPI, '体验指标拆解工具')
        break;
      case 'watchVideo':
        // @ts-ignore
        curVideo.current.playVideo();
        postToTask({ name: '观看讲解视频' });
        handleLink(false, '观看讲解视频')
        break;
      default:
    }
  }

  const renderEnterpriseService = (<div className={styles.enterpriseService}>
    <img width='1920px' src={enterpriseService_overall} />
    <VideoPlayer
      videoOpt={{
        sources: enterpriseService?.watchVideo,
      }}
      fuRef={curVideo}
    />
    <div className={classnames(styles.clickArea, styles.watchVideo)} onClick={() => handleToTask('watchVideo')}>
      <div className={styles.guidance}>
        <img width="90px" className={styles.guidanceLogo} src={finger_matting} />
      </div>
    </div>
    <div className={classnames(styles.clickArea, styles.experienceDemo)} onClick={() => {
      handleToTask('experienceDemo')
    }}>
      <div className={styles.guidance}>
        <img width="90px" className={styles.guidanceLogo} src={finger_matting} />
      </div>
    </div>
    <div className={classnames(styles.clickArea, styles.viewData)}>
      <div className={styles.guidance}>
        <img width="90px" className={styles.guidanceLogo} src={finger_matting} />
      </div>
      <div className={classnames(styles.descContent)}>
        <div className={classnames(styles.descBg, styles.position_left, styles.hoverShow)} style={{ backgroundImage: `url(${right_icon})` }}>
          <ul className={styles.content}>
            {enterpriseService?.viewData?.map(item => (
              <li key={item?.link} onClick={() => {
                if (!user) {
                  return showLoginDialog()
                }
                if (!item?.link) {
                  return
                }
                postToTask({ name: '查看干货资料' });
                handleLink(item?.link, '查看干货资料')
              }}>
                <span className={styles.contentHover}>
                  {item?.text}
                </span>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
    <div className={classnames(styles.clickArea, styles.experienceKPI)} onClick={() => {
      handleToTask('experienceKPI')
    }}>
        <div className={styles.guidance}>
        <img width="90px" className={styles.guidanceLogo} src={finger_matting} />
      </div>
    </div>   
  </div>)
  return (
    <Layout showFloat={false} showCookieAuth={false} hideFooter={true}>
      <Seo
        title={enterpriseService?.title}
        description={enterpriseService?.description}
        keywords={enterpriseService?.keywords}
        saTitle={enterpriseService?.saTitle}
      />
      <div className={classnames('block lg:hidden')}>
        <img width='100%' src={overall_catalogo_mb} />
      </div>
      <ScrollBarShowLayout className={classnames('hidden lg:block')} limitWidth={1920} innerH={937} childrenEle={renderEnterpriseService} getUser={(user) => setUser(user)}/>
    </Layout>
  )
}

export default EnterpriseService;